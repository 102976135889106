import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ACCESS_TOKEN, ADMIN, EXPIRE_CODE, ROLE_KEY, USER } from "../constants/constants";
import { showWarning } from './toast-utils';

const isExpireToken = () => {
  const now = new Date()
  if (now.getTime() > localStorage.getItem(EXPIRE_CODE)) {
    localStorage.clear();
    showWarning('Hết phiên đăng nhập!')
    return false
  }
  return true
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      localStorage.getItem(ACCESS_TOKEN) && isExpireToken()
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
);
